/* Center the login box in the viewport */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

/* Style the login box */
.login-box {
    width: 300px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 10px;
    text-align: center;
}

.login-box h2 {
    margin-bottom: 20px;
    color: #333;
}

.login-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.login-box button {
    width: 100%;
    padding: 10px;
    background-color: #34a364;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.login-box button:hover {
    background-color: #2d8b54;
}

.error {
    color: red;
    margin-bottom: 15px;
}