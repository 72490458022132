/* About Section */
#about {
    padding: 20px;
    background-color: var(--GreyBg);
    text-align: center;
}

.about-banner {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.banner-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.about-content {
    max-width: 800px;
    margin: 20px auto;
    background: var(--white);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-content h2 {
    color: var(--primary);
    margin-bottom: 20px;
}

.about-content p {
    color: var(--GreyText);
    line-height: 1.6;
    margin-bottom: 20px;
}

.developer-info {
    margin-top: 40px;
    text-align: center;
}

.developer-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid var(--primary);
    margin-bottom: 20px;
}

.developer-info p {
    color: var(--GreyText);
    font-size: 16px;
}