/* Footer Styling */
footer {
    background-color: var(--secondary);
    color: var(--white);
    padding: 20px;
    text-align: center;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
}

.social-media {
    margin-bottom: 20px;
}

.social-media a {
    margin: 0 10px;
    display: inline-block;
}

.social-media img {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    transition: transform 0.3s;
}

.social-media img:hover {
    transform: scale(1.1);
}

.contact-info p,
.copyright p {
    margin: 10px 0;
}

.contact-info a {
    color: var(--white);
    text-decoration: none;
}

.contact-info a:hover {
    color: var(--primary);
    text-decoration: underline;
}

@media (max-width: 768px) {
    .social-media img {
        width: 25px;
        height: 25px;
    }
}