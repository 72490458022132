/* Basic styles */
header {
    background: var(--secondary);
    color: var(--white);
    padding: 10px 0;
    z-index: 1000;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: var(--secondary);
    color: var(--white);
}

.navbar-left .logo {
    height: 50px;
}

.navbar-right {
    display: flex;
    align-items: center;
    position: relative;
}

/* Menu Toggle Button */
.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    z-index: 1001;
    color: var(--white);
}

.menu-toggle .menu-icon {
    display: block;
    width: 25px;
    height: 3px;
    background: var(--white);
    margin: 5px 0;
    z-index: 1001;
}

/* Navigation Links */
.nav-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-links li {
    margin: 0 10px;
}

.nav-links a {
    color: var(--white);
    text-decoration: none;
    padding: 5px 10px;
}

.nav-links a.active {
    background: var(--primary);
    border-radius: 4px;
}

/* Language Selector Styles */
.language-selector {
    position: relative;
    margin-left: 20px;
}

.language-toggle {
    background: none;
    border: none;
    cursor: pointer;
    padding: 20;
    display: flex;
    align-items: center;
}

.language-flag {
    width: 30px;
    height: 20px;
    vertical-align: middle;
}

/* Language Menu Styles */
.language-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: 20;
    background-color: var(--secondary);
    border: 1px solid var(--GreyText);
    list-style: none;
    padding: 0;
    margin: 0;
    width: auto;
    max-width: 100%;
    /* Ensure it doesn't go beyond screen width */
    z-index: 1000;
    white-space: wrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
    /* Add ellipsis if the text is too long */
}

.language-menu li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.language-menu li:hover {
    background-color: var(--GreyBg);
}

.language-menu .language-flag {
    margin-right: 10px;
}

.language-selector .language-menu {
    display: block;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .menu-toggle {
        display: block;
        margin-right: 15px;
    }

    .nav-links {
        display: none;
        flex-direction: column;
        width: 200;
        position: absolute;
        top: 100%;
        left: 0;
        padding-right: 20px;
        background-color: var(--secondary);
        z-index: 1000;
    }

    .nav-links.open {
        display: flex;
    }

    .nav-links li {
        margin: 20px 0;
        text-align: center;
    }

    .nav-links li a {
        padding: 10px;
        display: block;
        width: 100%;
        white-space: nowrap;
    }
}