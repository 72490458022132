/* addcamping.css */

.add-camping {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.add-camping h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: calc(100% - 10px);
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007BFF;
}

.form-group .switch-container {
    display: flex;
    align-items: center;
    position: relative;
}

.switch-container input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    width: 34px;
    height: 14px;
    background-color: #ccc;
    border-radius: 17px;
    transition: background-color 0.2s;
    cursor: pointer;
}

.switch-slider:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 0px;
    border-radius: 50%;
    background-color: white;
    top: -3px;
    left: -3px;
    transition: transform 0.2s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.switch-container input[type="checkbox"]:checked+.switch-slider {
    background-color: #007BFF;
}

.switch-container input[type="checkbox"]:checked+.switch-slider:before {
    transform: translateX(16px);
}

.save-button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #007BFF;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}

.required {
    color: red;
}

/* Dropdown styling */
.form-group select {
    appearance: none;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    width: calc(100% - 10px);
}

.form-group select:focus {
    border-color: #007BFF;
    outline: none;
}