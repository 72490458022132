/* statistics.css */

.statistics-container {
    margin: 20px;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    text-align: center;
    max-width: 600px;
    margin: auto;
}

.statistics-title {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
}

.statistics-subtitle {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 15px;
}