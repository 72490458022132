/* Features Section */
#features {
    padding: 40px 20px;
    background-color: var(--GreyBg);
    text-align: center;
}

#features h2 {
    color: var(--primary);
    margin-bottom: 30px;
}

.feature-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.feature {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 300px;
    background: var(--white);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    object-fit: cover;
}

.feature-text h3 {
    color: var(--primary);
    margin-bottom: 10px;
}

.feature-text p {
    color: var(--GreyText);
    line-height: 1.5;
}

@media (max-width: 768px) {
    .feature-container {
        flex-direction: column;
        gap: 20px;
    }

    .feature {
        max-width: 90%;
        margin: 0 auto;
    }
}