.camping-details-container {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    flex-direction: row;
    /* Default to row for larger screens */
    gap: 20px;
    /* Add some space between the card and the map */
}



.camping-card {
    width: 60%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
}

.camping-card h2 {
    margin-bottom: 10px;
}

.camping-card .contact-info {
    display: flex;
    color: var(--GreyText);
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
}

.camping-card .contact-info a {
    color: var(--GreyText);
    /* Use a color that fits your design */
    text-decoration: none;
}

.camping-card .contact-info a:hover {
    text-decoration: underline;
    color: var(--primary);
    /* Adjust color on hover if needed */
}

.contact-info div {
    display: flex;
    align-items: center;
    gap: 5px;
}

.contact-info img {
    width: 40px;
    height: 40px;
}

.contact-info a,
.contact-info span {
    display: block;
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflowed text */
    text-overflow: ellipsis;
    /* Show ellipsis (...) for overflowed text */
}

.icons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    /* Adjust spacing between icons */
}

.icon-item {
    text-align: center;
}

.icon-item img {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: auto;
}

.icon-item span {
    display: block;
    margin-top: 5px;
    /* Space between icon and label */
    font-size: 12px;
    /* Adjust font size as needed */
}

.map-container {
    width: 35%;
    height: 400px;
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
}

.map-container .leaflet-container {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.camping-card p {
    margin: 10px 0;
}



.camping-card strong {
    font-weight: bold;
}

.camping-card .ranking {
    display: flex;
    align-items: center;
    gap: 5px;
}

.camping-card .ranking img {
    width: 25px;
    height: 25px;
}

/* Media query to change layout for smaller screens */
@media (max-width: 768px) {
    .camping-details-container {
        flex-direction: column;
        /* Stack card and map vertically */
        align-items: center;
        /* Center them horizontally */
    }

    .map-container {
        width: 100%;
        /* Make map full-width */
        height: 300px;
        /* Adjust map height for smaller screens */
        margin-left: 0;
        /* Reset any left margin */
        margin-top: 20px;
        /* Add some space above the map */
    }

    .camping-card {
        width: 90%;
        /* Make card full-width */
        max-width: none;
        /* Remove max-width restriction */
    }




}

@media (max-width: 500px) {
    .icons img {
        width: 40px;
        /* Reduce icon size for smaller screens */
        height: 40px;
    }
}

@media (max-width: 300px) {
    .icons img {
        width: 30px;
        /* Reduce icon size for smaller screens */
        height: 30px;
    }
}

@media (max-width: 200px) {
    .icons img {
        width: 20px;
        /* Reduce icon size for smaller screens */
        height: 20px;
    }
}