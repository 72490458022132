/* Admin Page General Styles */
#admin {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
}

#admin h2 {
    color: #34A364;
    /* Primary color */
    margin-bottom: 20px;
    text-align: center;
}

#admin p {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

/* Tab Buttons */
.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tab-buttons button {
    background-color: #ebebeb;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1rem;
    color: #333;
}

.tab-buttons button.active {
    background-color: #34A364;
    /* Primary color */
    color: #fff;
}

.tab-buttons button:hover {
    background-color: #34A364;
    color: #fff;
}

.tab-content {
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Logout Button */
.logout-button {
    display: block;
    background-color: #F2141B;
    /* Alert/Action color */
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 20px auto 0;
    text-align: center;
}

.logout-button:hover {
    background-color: #cc0f16;
}