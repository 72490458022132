.add-article {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.article-form .form-group {
    margin-bottom: 15px;
}

.article-form label {
    display: block;
    margin-bottom: 5px;
}

.article-form input,
.article-form .ql-container {
    width: 100%;
    box-sizing: border-box;
}

.save-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.save-button:hover {
    background-color: #0056b3;
}

.error-message {
    color: red;
}

.success-message {
    color: green;
}

.ql-container {
    height: 400px;
    /* Adjust height as needed */
}

.ql-editor {
    min-height: 300px;
    /* Adjust minimum height as needed */
}