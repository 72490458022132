/* Overall container for centering */
.delete-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    /* Light background */
}

/* Box that contains the form */
.delete-box {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    /* Subtle shadow for depth */
    max-width: 400px;
    width: 100%;
    text-align: center;
    /* Center text inside the box */
}

/* Styling for the form groupings */
.form-group {
    margin-bottom: 20px;
    /* More space between form groups */
    text-align: left;
    /* Align labels to the left */
}

/* Label styling */
.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 14px;
    color: #333;
    /* Darker text for readability */
}

/* Input field styling */
.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s;
    /* Smooth transition for focus state */
}

/* Input field focus state */
.form-group input:focus {
    border-color: #007bff;
    /* Blue border on focus */
}

/* Button styling */
.delete-button {
    background-color: #d9534f;
    /* Red background */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s;
    /* Smooth transition for hover state */
    width: 100%;
    box-sizing: border-box;
}

/* Button hover state */
.delete-button:hover {
    background-color: #c9302c;
    /* Darker red on hover */
}

/* Error message styling */
.error-message {
    color: #d9534f;
    margin-bottom: 20px;
    font-size: 14px;
}

/* Success message styling */
.success-message {
    color: #5bc0de;
    margin-bottom: 20px;
    font-size: 14px;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.yes-button,
.no-button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.yes-button {
    background-color: #ff4d4d;
    color: white;
}

.no-button {
    background-color: #4d94ff;
    color: white;
}