/* Color variables */
:root {
    --primary: #34A364;
    --secondary: black;
    --SCR: #B646F3;
    --FC: #F2141B;
    --Stell: #10AEF3;
    --GreyText: #91959c;
    --GreyBg: #ebebeb;
    --HREF: #0645AD;
    --MenuGreen: #EAF0E1;
    --ActiveMenu: #748859;
    --white: #FFF;
}

/* Reset some default styles */
body,
h1,
h2,
h3,
p,
ul,
li,
form {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* General styling */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background: var(--GreyBg);
    color: var(--GreyText);
}

h1,
h2,
h3 {
    color: var(--primary);
}

section {
    padding: 20px;
    margin: 10px auto;
    max-width: 1200px;
    background: var(--white);
}

.cta-button {
    display: inline-block;
    padding: 10px 20px;
    background: var(--primary);
    color: var(--white);
    text-decoration: none;
    border-radius: 5px;
}

.feature {
    margin: 20px 0;
}