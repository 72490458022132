.edit-camping {
    padding: 20px;
}

.search-container {
    display: flex;
    margin-bottom: 20px;
}

.search-input {
    flex: 1;
    padding: 8px;
    font-size: 16px;
}

.search-button {
    padding: 8px 16px;
    font-size: 16px;
    margin-left: 10px;
}

.camping-list {
    list-style-type: none;
    padding: 0;
}

.camping-item {
    cursor: pointer;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.camping-item:hover {
    background-color: #f0f0f0;
}

.camping-details {
    margin-top: 20px;
}

.camping-form {
    display: flex;
    flex-direction: column;
}

.switch-container {
    display: flex;
    align-items: center;
    position: relative;
}

.switch-container input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch-slider {
    position: absolute;
    cursor: pointer;
    background-color: #ccc;
    border-radius: 34px;
    transition: .4s;
    width: 34px;
    height: 20px;
    margin-left: 10px;
}

.switch-slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: white;
    transition: .4s;
    left: 4px;
    bottom: 4px;
}

input:checked+.switch-slider {
    background-color: #4caf50;
}

input:checked+.switch-slider:before {
    transform: translateX(14px);
}

/* Adjust styles for form-group and other elements as needed */
.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
}

textarea {
    height: 100px;
    resize: vertical;
}

.save-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
}

.save-button:hover {
    background-color: #45a049;
}

.success-message {
    color: #4CAF50;
    /* Green color for success */
    font-weight: bold;
    margin-top: 20px;
}