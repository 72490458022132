.under-350-container {
    display: flex;
    flex-direction: column;
    height: 1200px;
    width: 97vw;
}

.header-350 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    width: 100%;
}

.header-350 h1 {
    margin: 0;
    width: 100%;
    text-align: center;
}

.ad-container-350 {
    margin-top: 10px;
    width: 100%;
    text-align: center;
}

.content-350 {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: row;
    width: 100%;

}

.camping-list-350 {
    width: 40%;
    padding: 20px;
    overflow-y: auto;
    border-right: 1px solid #ddd;
}

.camping-map-350 {
    height: 100%;
    margin-left: 5px;
    width: 60%;
}

.filters-350 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* Center the filters */
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
    /* Add margin at the top */
}

.filters-350 .MuiFormControl-root {
    margin-right: 20px;
}

.filters-350 .MuiInputLabel-outlined {
    color: #34A364;
    /* Same green color as your other elements */
}

.filters-350 .MuiOutlinedInput-root {
    color: #000;
    /* Default text color */
    border-radius: 8px;
    border-color: #34A364;
    /* Border color to match the theme */
}

.filters-350 .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: #34A364;
    /* Outline color */
}

.filters-350 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #34A364;
    /* Outline color when focused */
}

.filters-350 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #34A364;
    /* Outline color on hover */
}

.filters-350 .MuiSelect-select.MuiOutlinedInput-input {
    padding: 10px;
}

.filters-350 .MuiMenuItem-root {
    color: #000;
    /* Default text color for menu items */
}

.filters-350 .MuiMenuItem-root.Mui-selected {
    background-color: #34A364;
    /* Background color when an item is selected */
    color: #fff;
    /* White text for selected items */
}

.filters-350 .MuiMenuItem-root.Mui-selected:hover {
    background-color: #2d8a58;
    /* Darker green on hover when selected */
}

.filters-350 .MuiFormControl-root {
    min-width: 120px;
    /* Ensure the dropdowns have a minimum width */
}

.filters-350 .MuiSelect-icon {
    color: #34A364;
    /* Dropdown icon color */
}


/* Responsive adjustments */
@media (max-width: 768px) {
    .content-350 {
        flex-direction: column;
    }

    .camping-list,
    .camping-map {
        width: 90%;
    }

    .camping-list {
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .camping-map {
        display: none;
    }
}