.news-list {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.news-list h2 {
    font-size: 24px;
    margin-bottom: 20px;
}

.news-list ul {
    list-style-type: none;
    padding: 0;
}

.news-list li {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.news-list h3 {
    font-size: 20px;
    margin: 0;
}

.news-list p {
    margin: 5px 0;
}

.news-list a {
    color: #007bff;
    text-decoration: none;
}

.news-list a:hover {
    text-decoration: underline;
}