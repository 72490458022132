/* Home Section */
#home {
    padding: 20px;
    background-color: var(--GreyBg);
    text-align: center;
}

.banner {
    width: 100%;
    height: 300px;
    overflow: hidden;
    position: relative;
}

.banner-image {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
    display: block;
}

.content {
    padding: 20px;
    background: var(--white);
    max-width: 800px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.content h1 {
    color: var(--primary);
    margin-bottom: 20px;
}

.content p {
    color: var(--GreyText);
    margin-bottom: 15px;
    line-height: 1.6;
}

.download-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.store-badge {
    height: 50px;
    width: auto;
    cursor: pointer;
    transition: transform 0.3s;
}

.store-badge:hover {
    transform: scale(1.05);
}

/* Mobile Responsive */
@media (max-width: 768px) {
    .content {
        padding: 10px;
    }

    h1 {
        font-size: 2em;
    }

    p {
        font-size: 1em;
    }

    .store-badge {
        height: 40px;
    }
}