/* src/css/articlePage.css */

.article-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.article-page h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

.article-page p {
    font-size: 1em;
    margin-bottom: 10px;
}

.article-page .ql-editor {
    line-height: 1.5;
}